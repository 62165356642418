//
// Card
//

.card {
    margin-bottom: $grid-gutter-width;

    @if $enable-shadows {
      
        border: 0;
    }
    
}

.bg-status-standard{
    background: #001E31;
}
.bg-status-alert{
    background: #FF5F69;
}
.border-status-alert{
    background: #FF5F69;
}
.border-status-standart{
    background: #001E31;
}

.text-card-status{
    text-align: center;
    //font-size: 0.6125rem;
    font-weight: 800 !important;
    
}
.text-card-status-NORMAL{
    color:#00E7BD;
}

.text-card-status-ALERT{
    color:#FF5F69;
}
.card-font-montserrat{
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 600!important;
}
.card-font-watios{
    color:#00E7BD;
}
.card-max-width{
max-width: 75px;
}
.size-card-montserrat{
    font-size: 0.638rem!important;
    opacity: 0.7;
}
.card-rectangule{
border-radius: 3px;
width: auto;
padding-left: 10px;
padding-right: 10px;
display: inline-block;
height: 20px;
opacity:1;

}

.card-opacity{
    opacity: 0.5;
}

.card-status-battery-NORMAL{
   background: rgba(0, 231, 189, 0.4);
}
.card-status-battery-DISABLED{
    background: #979797;
}

.card-status-battery-ALERT{
    background: #FF5F69;
    background: rgba(255, 95, 105, 0.4);
}

.card-status-alert-special{
    background: rgba(255, 255, 255, 0.4);
}
.card-translucent {
    background-color: rgba(18, 91, 152, 0.08);
}

.batery-status{
    width: 19px;
    height: 19px;
    background-image:  url(../../../img/icons/batery_status.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 17px 17px;
    
}

.card-img-top {
    @if ($enable-shadows == false) {
        width: calc(100% + 2px);
        margin-left: -1px;
        margin-top: -1px;
    }
}

.card-img-bottom {
    @if ($enable-shadows == false) {
        width: calc(100% + 2px);
        margin-left: -1px;
        margin-bottom: -1px;
    }
}

// Card decks

.card-deck  {
    .card {
        margin-bottom: $grid-gutter-width;
    }
}


// Card with shdadow

.card {
    &.shadow {
        border: 0 !important;
    }
}


//
// Columns
//

.card-columns {
    @include media-breakpoint-up(sm) {
        column-count: 1;
    }

    @include media-breakpoint-up(md) {
        column-count: 2;
    }

    @include media-breakpoint-up(xl) {
        column-count: $card-columns-count;
        column-gap: $card-columns-gap;
    }
}
